<!--头部-->
<template>
  <div style="height: 50px; line-height: 50px; border-bottom: 1px solid #ccc; display:flex">
    <div style="width: 200px;padding-left: 30px; font-weight: bold; color: #1260cc">
      <img src="@/assets/logo1.png" alt="" style="width: 20px; position: relative; top: 5px;">
      信息技术学习平台
    </div>
    <div style="flex: 1;text-align: right">
    </div>
    <div style="width: 100px; padding-top:18px">
      <div class="right-menu">
        <el-dropdown class="avatar-container" trigger="click">

          <!--              <div class="avatar-wrapper">-->
          <!--                < img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar">-->
          <!--                <i class="el-icon-caret-bottom" />-->
          <!--              </div>-->

          <span class="el-dropdown-link" style="color:#1260cc;cursor: pointer">
                  {{ user.username }}
                <el-icon class="el-icon--right">
                    <arrow-down />
                 </el-icon>
                </span>

          <el-dropdown-menu slot="dropdown" class="user-dropdown">

            <el-dropdown-item @click="$router.push('/front/user')">个人空间</el-dropdown-item>

            <el-dropdown-item>通知</el-dropdown-item>

            <el-dropdown-item @click="logout">注销</el-dropdown-item>

          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowDown } from '@element-plus/icons-vue'
import user from "@element-plus/icons/lib/User";
export default {
  name: "Header",
  props: ['user'],
  data() {
    return {
      user: {}
    }
  },
  created() {
    let userStr = sessionStorage.getItem("user") || "{}"
    this.user = JSON.parse(userStr)
  },
  components:{
    ArrowDown
  },
  methods:{
    logout() {
      this.$router.push("/login")
      window.sessionStorage.removeItem("user")
      this.$message.success("退出成功")
    }
  }
}
</script>

<style scoped>
.right-menu {
  float: right;
  height: 100%;
  line-height: 50px;

  &:focus {
    outline: none;
  }

  .right-menu-item {
    display: inline-block;
    padding: 0 8px;
    height: 100%;
    font-size: 18px;
    color: #5a5e66;
    vertical-align: text-bottom;

    &.hover-effect {
      cursor: pointer;
      transition: background .3s;

      &:hover {
        background: rgba(0, 0, 0, .025)
      }
    }
  }

  .avatar-container {
    margin-right: 30px;

    .avatar-wrapper {
      margin-top: 5px;
      position: relative;

      .user-avatar {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }

      .el-icon-caret-bottom {
        cursor: pointer;
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }
}
</style>